<template>
  <!-- 點數popup -->
  <section
    ref="popPoints"
    id="popPoints"
    class="pop points"
    @click="close('close')"
  >
    <div class="popBody">
      <a v-if="userPoints.length" class="close" @click="disablePointUse">
        <u> {{ $t("Not use points") }} </u>
      </a>
      <a v-else class="close" @click="close('close')">
        <u> {{ $t("Close") }} </u>
      </a>
      <span class="title"> {{ $t("Choose Point") }}</span>

      <!-- 點數 -->
      <template v-if="isLoggedIn && !isPointsLoaded">
        <!-- 點數Loading -->
        <div class="loading transparent loading--body">
          <div>
            <img src="@/assets/icon/pPoint.svg" />
            <div></div>
          </div>
        </div>
      </template>
      <template v-else-if="userPoints.length">
        <div
          v-for="(point, index) in userPoints"
          :key="index"
          :ref="'pointcard-' + index"
          class="point__card"
          @click.stop="return;"
        >
          <div>
            <div class="card__top" @click.stop="openPointCard(index)">
              <span class="card__name"> 
                <div class="card__icon">
                  <img v-if="point.icon" :src="point.icon" />
                  <img v-else-if="point.pointLogo" :src="point.pointLogo" />
                </div>
                {{ point.pointName }} 
              </span>
              <span class="card__content" @click.stop="openPointCard(index)">
                <a class="card__content__btn" >
                  <font-awesome-icon icon="fa-solid fa-caret-down"></font-awesome-icon>
                </a>
              </span>
              <span class="card__content opened">
                <div class="card__content__input">
                  <input
                    :ref="'pointAmount-' + index"
                    type="tel"
                    placeholder=""
                    :value="point.usingPoints"
                    :id="index"
                    @input="onPointAmountChange($event, point)"
                    @click.stop=""
                    pattern="^//d+$"
                  />
                  <!-- <input type="text" value="10" placeholder="10" /> -->
                  <span>/{{ point.pointBalance }}</span>
                </div>
                <a class="card__content__btn" @click.stop="openPointCard(index)">
                  <font-awesome-icon icon="fa-solid fa-caret-up"></font-awesome-icon>
                </a>
              </span>
            </div>
            <a class="card__action" @click.stop="usePointCard(index)">
              <span> {{ $t("Use point") }} </span>
            </a>
            <div class="card__rate">
              <div class="card__icon">
                <img v-if="point.icon" :src="point.icon" />
                <img v-else-if="point.pointLogo" :src="point.pointLogo" />
              </div>
              <span>
                {{ point.pointsToCurrency.points }} = NT${{
                  point.pointsToCurrency.currency
                }}
              </span>
            </div>
            <span class="card__description"> {{ point.description }}</span>
          </div>
        </div>
      </template>
      
      <template v-else>
        <!-- 無點數 -->
        <div class="points--none">
          <img src="@/assets/icon/pPoint.svg" />
          <span>{{ $t("No points available") }}</span>
          <!-- <a>
            {{ $t("Go to wallet to bind") }}
            <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" />
          </a> -->
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "PopupPoints",
  props: {},
  data() {
    return {
      selectedPoint: null,
      userPoints: [],
      noticePopup: {
        type: null,
        title: null,
        message: null,
        okBtn: null,
        cancelBtn: null,
        showCancelBtn: false,
        cancelFunc: null,
        okFunc: null,
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      isPointsLoaded: (state) => state.isPointsLoaded,
    }),
    ...mapGetters({
      shopId: "getShopId",
      userId: "getUserId",
      memberId: "getMemberId",
      availablePointTypes: "getAvailablePointTypes",
      merchantId: "getMerchantId",
      orderAmount: "getAmount", // 訂單金額
      ticketDiscount: "getTicketDiscounts",
      currentSelected: "getSelectedPoint",
    }),
    payableAmount() {
      return this.orderAmount - this.ticketDiscount;
    },
  },
  watch: {
    userId: {
      handler(newVal) {
        if (newVal) {
          this.getUserPoints();
        }
      },
    },
    "availablePointTypes.length": {
      handler() {
        this.userPoints = [...this.availablePointTypes];
      },
    },
  },
  mounted() {
    console.log("[PopupPoints] mounted:" + this.availablePointTypes.length);
    this.userPoints = [...this.availablePointTypes];
    this.popup();
  },
  methods: {
    close(eventName) {
      console.log("[PopupPoints] close PopupPoints - " + eventName);
      if (eventName === "select") {
        console.log("[PopupPoints] select to use point", this.selectedPoint);
        this.$store.commit("updateSelectedPoint", this.selectedPoint);
      } else if (eventName === "disabled") {
        console.log("[PopupPoints] disable point use.");
        this.$store.commit("updateSelectedPoint", null);
      }

      const self = this;
      const pop = this.$refs.popPoints; // document.getElementById('popError');
      pop.children[0].style.animationName = "popdown";
      setTimeout(function () {
        document.querySelector("body").setAttribute("style", "overflow:auto");
        self.$emit("close", eventName);
      }, 250);
    },
    popup() {
      const target = this.$refs.popPoints;
      // const self = this;
      // window.onclick = function (event) {
      //   if (event.target == target) {
      //     target.style.display = "none";
      //     self.close("close");
      //   }
      // };
      target.style.display = "flex";
      document.querySelector("body").setAttribute("style", "overflow:hidden");
    },
    getMaxDiscountPoints(point) {
      // 最多可折抵的金額
      let maxAmount =
        Math.min(point.redeemableDollars, this.payableAmount) || 0;
      let maxPoints =
        (maxAmount / point.pointsToCurrency.currency) *
        point.pointsToCurrency.points;
      return Math.ceil(maxPoints);
    },
    // isNumber(evt) {
    //   evt = (evt) ? evt : window.event;
    //   var charCode = (evt.which) ? evt.which : evt.keyCode;
    //   // console.log('charcode:' + charCode)
    //   // if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    //   if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
    //     evt.preventDefault();;
    //   } else {
    //     return true;
    //   }
    // },
    onPointAmountChange($event) {
      // console.log('[point] onPointAmountChange:' + this.amount)
      // TBD 如何避免台幣時總金額會出現小數點的情況
      let val = $event.target.value.replace(/[^0-9]/g, ""); // strips letters, keeps numbers
      val = val.replace(".", "");

      // 不能在這裡判斷
      // function isFloat(n){
      //   return Number(n) === n && n % 1 !== 0;
      // }
      // if ( isFloat (val /  point.pointsToCurrency.points * point.pointsToCurrency.currency )) {
      //   const quotient = Math.floor(val / point.pointsToCurrency.points);
      //   val = quotient * point.pointsToCurrency.points;
      // }

      const index = $event.target.id;
      const maxPoints = this.getMaxDiscountPoints(this.userPoints[index]);
      if (Number(val) > maxPoints) {
        this.userPoints[index].usingPoints = maxPoints;
        $event.target.value = maxPoints;
      } else if (val === this.userPoints[index].usingPoints) {
        $event.target.value = Number(val);
      } else {
        $event.target.value = Number(val);
        this.userPoints[index].usingPoints = Number(val);
      }
      let _input = this.$refs["pointAmount-" + index][0];
      let pos = val.length || 1;
      _input.focus();
      this.$nextTick(() => {
        _input.setSelectionRange(pos, pos);
      });
      // this.$emit("amountChanged", this.amount || 0);
    },
    openPointCard(pointIndex) {
      let cards = document.getElementsByClassName("point__card");
      const target = `pointcard-${pointIndex}`;
      let cardElem = this.$refs[target][0];
      // console.log('cardElem', cardElem)
      const isOpened = cardElem.style.animationName === "openCard";
      for (let i = 0; i < cards.length; i++) {
        let card = cards.item(i);
        card.style.animationDelay = "0s";
        if (card.style.animationName === "openCard")
          card.style.animationName = "closeCard";
        card.style.height = "60px";
        card.style.minHeight = "60px";
        card.classList.remove('opentest');
      }
      if (isOpened) {
        cardElem.style.animationName = "closeCard";
        cardElem.style.height = "60px";
        cardElem.style.minHeight = "60px";
        cardElem.classList.remove('opentest');
      } else {
        cardElem.style.animationName = "openCard";
        cardElem.style.height = "400px";
        cardElem.style.minHeight = "400px";
        cardElem.classList.add('opentest');
      }

      // 如果是已選擇的點數，保留原使用點數
      if (
        this.currentSelected?.pointCode ===
        this.userPoints[pointIndex].pointCode
      ) {
        this.$nextTick(() => {
          this.userPoints[pointIndex].usingPoints =
            this.currentSelected.usingPoints;
        });
      } else {
        this.$nextTick(() => {
          this.userPoints[pointIndex].usingPoints = this.getMaxDiscountPoints(
            this.userPoints[pointIndex]
          );
        });
      }
      // console.log(this.userPoints[pointIndex]);
    },
    
    usePointCard(index) {
      console.log("usePointCard");
      // 選擇使用的，其它的amount要歸0， 或有flag
      this.selectedPoint = Object.assign({}, this.userPoints[index]);
      // TBD： 注意使用點數折抵的金額是否為整數....
      // TBD: 是否需要提示系統有自動調整使用的點數，以及點數折抵的金額一定要是整數嗎（非台幣的情況）？
      function isFloat(n) {
        return Number(n) === n && n % 1 !== 0;
      }
      const usingPoints = this.selectedPoint.usingPoints;
      const needToAdjust = isFloat(
        (usingPoints / this.selectedPoint.pointsToCurrency.points) *
          this.selectedPoint.pointsToCurrency.currency
      );
      if (needToAdjust) {
        const quotient = Math.floor(
          usingPoints / this.selectedPoint.pointsToCurrency.points
        );
        console.log("adjust point: " + quotient);
        const newUsingPoints =
          quotient * this.selectedPoint.pointsToCurrency.points;
        this.selectedPoint.usingPoints = newUsingPoints;
        this.userPoints[index].usingPoints = newUsingPoints;
        this.close("select");
      } else {
        this.close("select");
      }
    },
    disablePointUse() {
      this.close("disabled");
    }
  },
};
</script>
