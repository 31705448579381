import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import VueNotification from 'vue-notification'
import mixin from './mixins/index'
import VueTheMask from 'vue-the-mask'
import Vconsole from 'vconsole'

// 在 test 环境才使用
console.log('env:' + process.env.VUE_APP_ENV)
if (process.env.VUE_APP_VCONSOLE === 'true' ) {
  console.log('show v-console');
  new Vconsole();
}

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faDeleteLeft, faChevronRight, faChevronLeft, faTimes, faExclamationCircle, 
  faCreditCard, faCopy, faCheck, faMoneyBillWave, faExclamation,
  faTriangleExclamation, faSquareCaretDown, faSquareCaretUp, faPlus,
  faBarcode, faCircleUser, faChevronDown, faChevronUp, faArrowUpRightFromSquare,
  faCaretUp, faCaretDown  } from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark, faCircleCheck, faHourglassHalf, faFaceSadTear} from '@fortawesome/free-regular-svg-icons'
import { faLine } from '@fortawesome/free-brands-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* add icons to the library */
library.add(faDeleteLeft, faChevronRight, faChevronLeft, faTimes, 
    faCircleXmark, faCircleCheck, faExclamationCircle, faCreditCard, 
    faCopy, faCheck, faLine, faHourglassHalf, faMoneyBillWave, 
    faExclamation, faTriangleExclamation, faFaceSadTear, faSquareCaretDown,
    faSquareCaretUp, faPlus, faBarcode, faCircleUser, faChevronDown, faChevronUp, 
    faArrowUpRightFromSquare, faCaretUp, faCaretDown )
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(VueNotification)
Vue.use(VueTheMask)

Vue.mixin(mixin); 
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// const root = new Vue({
//   i18n,
//   router,
//   store,
//   render: h => h(App)
// })

// document.addEventListener('DOMContentLoaded', function () {
//   root.$mount('#app')
// })