<template>
  <div class="result">
    <!-- Loading -->
    <Loading :spin="!isOrderLoaded" :show-background="false" />
    <!-- <div v-if="!isOrderLoaded" class="loading bg">
      <div>
        <div></div>
      </div>
    </div> -->
    <!-- Header -->
    <!-- <LogoHeader /> -->
    <OrderHeader :result-page="true" />
    <!-- Footer -->
    <section class="foot" v-if="false && isLoggedIn && continueUrl">
      <button @click="gotoContinueUrl">
        <span class="action__total"> {{ $t("Check Reward") }} </span>
        <div class="action__name">
          <span> {{ $t("Member Centre") }} </span>
        </div>
      </button>
    </section>
    <section class="foot" v-if="paymentStatus === 'failed'">
      <button @click="repay">
        <span class="action__total"> {{ $t("Repay") }}  </span>
      </button>
    </section>

    <!-- Main -->
    <section class="main">
      <section class="detail">
        <div class="top">
          <div class="icon" v-if="isOrderLoaded">
            <font-awesome-icon
              v-if="paymentStatus === 'done'"
              icon="fa-regular fa-circle-check"
              size="5x"
            />
            <div v-else-if="paymentStatus === 'issued'" class="orange">
              <font-awesome-icon
                icon="fa-regular fa-hourglass-half"
                size="2x"
                class="orange"
              />
            </div>
            <font-awesome-icon
              v-else
              icon="fa-regular fa-circle-xmark"
              size="5x"
            />
          </div>
          <span v-if="paymentStatus === 'done'" class="title green">
            <template v-if="noPaymentServices"> 
              {{ $t("Discount successfully") }}
            </template>
            <template v-else-if="isOnsitePaymentRequired"> 
              <span
                class="strong"
              >
              {{ $t("Pay on site") }} {{ formatCurrency(remainingAmount, currencySettings) }}
              </span>
            </template>
            <template v-else> 
              {{ $t("Payment successful") }}
            </template>
            
          </span>
          <span v-else-if="paymentStatus === 'failed'" class="title red">
            {{ $t("Payment failed") }}
          </span>
          <span v-else-if="paymentStatus === 'cancelled'" class="title red">
            {{ $t("Payment canceled") }}
          </span>
          <span v-else-if="paymentStatus === 'issued'" class="title orange">
            {{ $t("Payments") }}
          </span>

          <span v-if="noPaymentServices && remainingAmount > 0" class="strong"> {{ $t('Pay remaining amount to shop', {amount: formatCurrency(remainingAmount, currencySettings) }) }} </span>
          <span v-else-if="!noPaymentServices" class="cost"> {{ formatCurrency(order.amount, currencySettings) }}</span>
          <div v-if="!noPaymentServices">
            <img
              v-if="order.paymentAmount > 0 && paymentMethod.icon"
              :src="paymentMethod.icon"
            />
            <font-awesome-icon
              v-else-if="order.paymentAmount > 0"
              icon="fa-solid fa-credit-card"
            />
            <span v-if="order.paymentAmount > 0">
              {{ order.paymentAmount }}</span
            >
            <span v-if="order.paymentAmount > 0 && order.points">+</span>
            <img v-if="order.points" :src="pointLogo" />
            <span v-if="order.points">{{ order.points }}</span>
            <span
              v-if="(order.paymentAmount > 0 || order.points) && order.ticketId"
              >+</span
            >
            <img v-if="order.ticketId" src="@/assets/icon/pCoupon.svg" />
            <span v-if="order.ticketId">{{
              order.amount - order.ticketQuotation
            }}</span>
          </div>
          <!-- <span class="strong"> {{ $t('Pay remaining amount to shop', {amount: formatCurrency(remainingAmount, currencySettings) }) }} </span> -->
        </div>
        <div class="center">
          <div>
            <span> {{ $t("Amount of consumption") }} </span>
            <span>
              {{ formatCurrency(order.amount, currencySettings) }}
            </span>
          </div>
          <div v-if="order.pointCode" class="sub">
            <span> {{ $t("Point payment") }}【{{ order.pointName }}】 </span>
            <img :src="pointLogo" />
            <span> {{ order.points }} <!-- / {{ formatCurrency(order.pointAmount, currencySettings) }} --></span>
          </div>
          <div v-if="order.ticketId" class="sub">
            <span v-if="order.ticketTitle">
              {{ $t("Coupon discount") }}【{{ order.ticketTitle }}】
            </span>
            <span v-else> {{ $t("Coupon discount") }} </span>
            <img src="@/assets/icon/pCoupon.svg" />
            <span>
              {{ formatCurrency(order.amount - order.ticketQuotation, currencySettings) }}
            </span>
          </div>
          <div v-if="order.paymentAmount > 0" class="sub">
            <span v-if="paymentMethod.label"> {{ paymentMethod.label }} </span>
            <span v-else> {{ $t("Other payment") }} </span>
            <img
              v-if="order.paymentAmount > 0 && paymentMethod.icon"
              :src="paymentMethod.icon"
            />
            <font-awesome-icon
              v-else-if="order.paymentAmount > 0"
              icon="fa-solid fa-credit-card"
            />
            <span>
              {{ formatCurrency(order.paymentAmount || 0, currencySettings) }}
            </span>
          </div>
          <div v-if="isOnsitePaymentRequired" class="sub">
            <span> {{ $t('paymentMethods.offline') }} </span>
            
            <font-awesome-icon icon="fa-solid fa-money-bill-wave"/>
            <span>
              {{ formatCurrency(remainingAmount || 0, currencySettings) }}
            </span>
          </div>
          <div>
            <span> {{ $t("Transaction time") }} </span>
            <span> {{ orderCreatedTime }} </span>
          </div>
          <div>
            <span> {{ $t("Transaction No") }} </span>
            <span> {{ order.orderId }} </span>
          </div>
          <div class="lineH"></div>
          <!-- <div>
            <span>店家</span>
            <span>{{ order.shopName }}</span>
          </div> -->
          <div v-if="billingInfo.name">
            <span> {{ $t("Payer") }} </span>
            <span>{{ billingInfo.name }}</span>
          </div>
          <div v-if="billingInfo.phone">
            <span> {{ $t("Phone") }} </span>
            <span>{{ billingInfo.phone }}</span>
          </div>
          <div
            v-if="billingInfo?.name || billingInfo.phone"
            class="lineH"
          ></div>
        </div>
        <div class="receipt__deco">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <!-- 回饋 -->
        <!-- <div class="bottom" >
          <span class="title"> {{ $t("Reward program") }} </span>
          <span class="remark" v-html="$t('rewardReminder')"></span>
          <div>
            <img src="@/assets/icon/mPoint.svg" />
            <span>筷點</span>
            <span>+10</span>
          </div>
          <div>
            <img src="@/assets//icon/mTicket.svg" />
            <span>優惠券</span>
            <span>滿$100折$10</span>
          </div>
          <div>
            <img src="@/assets/icon/mStamp.svg" />
            <span>集章</span>
            <span>新春集章活動 x1</span>
          </div>
        </div> -->
        <!-- 按鈕 -->
        <!-- <div v-if="cardNo" class="bottom">
          <div style="text-align: center">
            <barcode
              :value="cardNo"
              format="code128"
              width="2"
              height="60px"
              displayValue="true"
              :margin="3"
            >
            </barcode>
          </div>
        </div> -->
        <!-- 按鈕 -->
        <!-- <div class="buttons"> -->
        <!-- <button v-if="cardNo" @click="showBarcode = true">
            <i class="fa-solid fa-barcode"></i>
            <span> {{ $t("載具條碼") }}</span>
          </button> -->
        <!-- <button>
            <span>會員中心</span>
          </button> -->
        <!-- <button v-if="paymentStatus" @click="gotoPage"> -->
        <!-- <button
            v-if="paymentStatus && order.context && order.context.resultUrl"
            @click="gotoPage"
          >
            <span v-if="order.context && order.context.resultUrl">
              {{ $t("Goto Order") }}
            </span>
            <span v-else> {{ $t("Goto Wallet") }} </span>
          </button>
          <button
            v-if="paymentStatus === 'failed' && !isTokenExpired"
            @click="repay"
          >
            <span> {{ $t("Repay") }} </span>
          </button> -->
        <!-- </div> -->
      </section>
      <!-- DEMO ONLY -->
      <template v-if="false && isLoggedIn && (order?.paymentAmount > 0 && rewards?.length)">
        <div
          v-for="(reward, index) in rewards"
          :key="index"
          :ref="'couponCard-' + index"
          class="coupon--ddp"
        >
          <div class="card__top">
            <div class="card__info">
              <span class="card__detail card__detail--strong">
                {{ $t("rewardReminder") }}
              </span>
              <span class="card__name">{{ reward.name }}</span>
              <span v-if="reward.useExpirationTs" class="card__detail">
                {{ $t("Expiration Date") }}
                {{ tsToDateTime(reward.useExpirationTs) }}
              </span>
            </div>
            <img v-if="reward.imageUrl" :src="reward.imageUrl" />
          </div>
          <div class="dash"></div>
          <span class="card__description">
            <div v-html="reward.description"></div>
          </span>
          <a class="card__action" @click.stop="onClickMore($event, index)">
            <span> {{ $t("See more") }} </span>
            <font-awesome-icon
              :ref="'couponCardArrow-' + index"
              class="card-arrow"
              icon="fa-solid fa-chevron-down"
            />
          </a>
          <div class="card__tag">
            <span> {{ $t("Consumer rewards") }} </span>
          </div>
        </div>
      </template>

      <!-- 加入店家Line -->
      <a class="line" v-if="continueUrl"  @click="gotoContinueUrl">
        <img src="@/assets/icon/line.svg" />
        <div>
          <span> {{ $t("Join Line office account") }}</span>
          <span> {{ $t("Receive discount information instantly") }} </span>
        </div>
        <font-awesome-icon icon="fa-solid fa-chevron-right" size="xl" />
      </a>

      <!-- 邀請好友 -->
      <ReferralButton v-if="false" />
      <!-- 加入會員 -->
      <!-- 不共用component，因為CSS不同 -->
      <a
        class="join"
        v-if="false && ssoAppId && !isLoggedIn && shopSupportedPoints.length"
        @click="signin"
      >
        <img src="@/assets/img/patternST.png" />
        <span v-html="$t('Join member')"></span>
        <font-awesome-icon icon="fa-solid fa-chevron-right"></font-awesome-icon>
      </a>
    </section>
    <PopupInvite />
    <PopupBarcode v-if="showBarcode" :card-no="userCarrier" />
    <PopupLogout v-if="showLogout" :redirect="logoutRedirect"/>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from "vuex";
import OrderHeader from "@/components/OrderHeader.vue";
import {
  fetchOrderInfoByOrderId,
  // fetchStoreServices,
  fetchDDCRewards,
} from "@/apis/fetchData";
import moment from "moment-timezone";
import ReferralButton from "@/components/ReferralButton.vue";
import PopupInvite from "@/components/PopupInvite.vue";
import PopupBarcode from "@/components/PopupBarcode.vue";
import PopupLogout from "@/components/PopupLogout.vue";

import { getPaymentImage } from "@/utils/utils";
// import VueBarcode from "vue-barcode";
import Loading from "@/components/Loading.vue";
import { formatCurrency } from "@/utils/utils";

export default {
  name: "Payment",
  components: {
    OrderHeader,
    ReferralButton,
    PopupInvite,
    Loading,
    PopupBarcode,
    PopupLogout
    // barcode: VueBarcode,
  },
  props: {},
  data() {
    return {
      formatCurrency: formatCurrency,
      isOrderLoaded: false,
      price: 0,
      order: {},
      paid: 0,
      retry: 0,
      timeoutId: null,
      retryInterval: 2000, // ms
      retryLimit: 3 * 60 * 1000, // ms
      rewards: null,
      // showBarcode: false,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   // called before the route that renders this component is confirmed.
  //   // does NOT have access to `this` component instance,
  //   // because it has not been created yet when this guard is called!
  //   // console.log(to, from);
  //   next((vm) => {
  //     let that = vm;
  //     const orderId = to.query.orderId;
  //     console.log('payment orderId:' + orderId)
  //     // get order Info by order Id;
  //     fetchOrderInfoByOrderId(orderId)
  //       .then((res) => {
  //         console.log('fetchOrderInfoByOrderId done', res);
  //         vm.order = Object.assign({},res.data);
  //         vm.$store.commit('updatePaidOrderInfo', res.data);
  //         vm.$store.commit('updateOrderInfo', res.data.context);
  //         fetchStoreSsoAppId(vm.shopId)
  //         .then( (res) => {
  //           console.log('fetchStoreSsoAppId done:', res);
  //           const appId = res.data.sso && res.data.sso.appId ? res.data.sso.appId : null;
  //           vm.$store.commit('updateSSOAppId', appId )
  //         })
  //         .catch((e) => {
  //           console.error('fetchStoreSsoAppId failed:', e);
  //           vm.$store.commit('updateSSOAppId', null)
  //         })
  //         .finally(() => {
  //           next();
  //         })
  //       })
  //       .catch((e) => {
  //         console.error('fetchOrderInfoByOrderId failed', e);
  //       });

  //   })
  // },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoAppId: (state) => state.ssoAppId,
      user: (state) => state.user,
      defaultPointLogo: (state) => state.defaultPointLogo,
      showBarcode: (state) => state.showUserCarrier,
      showLogout: (state) => state.showLogout,
      // order: (state) => state.paidOrderInfo,
    }),
    ...mapGetters({
      // isTokenExpired: 'isTokenExpired',
      shopId: "getShopId",
      shopName: "getShopName",
      shopSupportedPoints: "getShopSupportedPointTypes",
      // availablePointTypes: "getAvailablePointTypes",
      shopSettings: "getShopSettings",
      userId: "getUserId",
      merchantId: "getMerchantId",
      portalInfo: "portal/getPortalInfo",
      userCarrier: "getUserCarrier"
    }),
    logoutRedirect () {
      return {
        // endpoint: `${window.location.origin}/result?orderId=${this.orderId}`,
        endpoint: `${window.location.origin}/results`,
        token: this.orderId
      }
    },  
    orderId() {
      return this.$route.params.orderId || this.$route.query.orderId;
    },
    currencySettings() {
      return this.order?.context?.orderInfo?.currencySettings || null;
    },
    noPaymentServices () {
      return this.portalInfo?.disableOnlinePayment || this.order?.context?.options?.noPaymentServices;
    },
    token() {
      return this.order.token;
    },
    billingInfo() {
      return (this.order.context && this.order.context.billingInfo) || {};
    },
    paymentStatus() {
      return this.order.status;
      // return this.order.status === 'issued'
      //   ? 'issued'
      //   : this.order.status === 'completed' || this.order.status === 'done'
      //   ? 'done'
      //   : 'failed';
    },
    orderCreatedTime() {
      return this.order.createdAt
        ? moment.unix(this.order.createdAt).format("YYYY/MM/DD HH:mm")
        : "";
    },
    paymentMethod() {
      // let label = "";
      let payment = null;
      if (this.isOrderLoaded) {
        // label = "";
        payment = getPaymentImage(
          this.order.paymentService,
          this.order.paymentType
        );
        // const method = `${this.order.paymentService}-${this.order.paymentType}`;
        // switch (method) {
        //   case "cardpay-apple":
        //   case "tappay-apple":
        //     label = "Apple Pay";
        //     break;
        //   case "cardpay-google":
        //   case "tappay-google":
        //     label = "Google Pay";
        //     break;
        //   case "cardpay-line":
        //   case "tappay-line":
        //   case "linepay-linepay":
        //     label = "LINE Pay";
        //     break;
        //   case "easywallet-easywallet":
        //     // label = "悠遊付";
        //     label = this.$i18n.t('paymentMethods.easywallet');
        //     break;
        //   case "jko-jko":
        //     // label = "街口支付";
        //     label = this.$i18n.t('paymentMethods.jko');
        //     break;
        //   case "taiwanpay-twqrp":
        //     // label = "台灣Pay";
        //     label = this.$i18n.t('paymentMethods.taiwanpay')
        //     break;
        //   case "cardpay-credit":
        //   case "tappay-direct":
        //   case "olink-credit":
        //     // label = "信用卡";
        //     label = this.$i18n.t('paymentMethods.credit');
        //     break;
        //   case "icashpay-icashpay":
        //     label = this.$i18n.t('paymentMethods.icashpay');
        //     break;
        //   case "fastek-gcash-ewallet":
        //     label = this.$i18n.t('paymentMethods.gcash');
        //     break;
        //   default:
        //     label = "";
        //     break;
        // }
      }

      return {
        icon: payment?.icon,
        label: payment.label
      };
    },
    maxRetry() {
      return Math.floor(this.retryLimit / this.retryInterval);
    },
    pointLogo() {
      let point = null;
      if (this.order && this.order.pointCode) {
        point = this.shopSupportedPoints.find((item) => {
          console.log("item.pointCode:" + item.pointCode);
          return String(item.pointCode) === String(this.order.pointCode);
        });
      }
      return point && point.pointLogo ? point.pointLogo : this.defaultPointLogo;
    },
    isTokenExpired() {
      return moment().diff(moment.unix(this.order.expirationTs)) > 0;
    },
    // cardNo() {
    //   if (this.user && this.user.user && this.user.user.cardNo) {
    //     return this.user.user.cardNo.trim();
    //   }
    //   return null;
    // },
    continueUrl() {
      return this.shopSettings?.portal?.continueUrl || this.shopSettings?.portal?.services?.continueUrl;
    },
    remainingAmount () {      
      const total = this.order?.amount || 0;
      const pointAmount = this.order?.pointAmount || 0;
      const ticketQuotation = this.order?.ticketQuotation;
      const ticketDiscount = (ticketQuotation === undefined || ticketQuotation === null) ? 0 : total - ticketQuotation;
      // console.log('----- debug ----', total, pointAmount, ticketQuotation, ticketDiscount);
      return Math.max(0, total - pointAmount - ticketDiscount);
    }, 
    isOnsitePaymentRequired () {
      // 訂單明細裡目前沒有明確說是現場支付，所以先workaround
      if (this.remainingAmount && !this.paymentService && !this.order?.paymentAmount) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    shopId(newVal) {
      if (newVal) {
        // this.getSSOAppId();
        // this.getShopSupportedPoints();
        this.getShopSettings();
      }
    },
    isLoggedIn(newVal) {
      console.log('watch isLoggedIn:' + newVal);
      if (newVal) {
        this.getDDCReward();
      }
    },
  },
  created() {
    // 清除付款金額資訊
    this.$store.commit("updateAmount", 0);
    this.init();
  },
  mounted() {
    // setTimeout(this.init, 100);
    // 清除付款金額資訊
    // this.$store.commit('updateAmount', 0);
    // this.init();
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    init() {
      console.log("init result:" + this.orderId);
      // this.getSSOAppId(); // for init sso
      this.getOrderInfo();
      this.getDDCReward();
    },
    getOrderInfo() {
      fetchOrderInfoByOrderId(this.orderId)
        .then((res) => {
          console.log("fetchOrderInfoByOrderId done:" + JSON.stringify(res));
          // 先跳轉，避免看到這一頁的訊息
          if (res.data && res.data.context && res.data.context.resultUrl) {
            window.location.href = res.data.context.resultUrl;
            return;
          }
          this.order = Object.assign({}, res.data);
          // this.$store.commit('updatePaidOrderInfo', res.data);
          this.$store.commit("updateOrderInfo", res.data.context);
          this.isOrderLoaded = true;
          // if (!this.ssoAppId) {
          //   this.getSSOAppId();
          // }

          if (this.order.status === "issued" && this.retry < this.maxRetry) {
            this.retry++;
            console.log(`retry after ${this.retryInterval} ms`);
            this.timeoutId = setTimeout(this.getOrderInfo, this.retryInterval);
          } else {
            this.retry = 0;
            this.timeoutId = null;
          }
        })
        .catch((e) => {
          console.error("fetchOrderInfoByOrderId failed", e);
          if (this.retry < 5) {
            this.retry++;
            console.log(`retry after ${this.retryInterval} ms`);
            this.timeoutId = setTimeout(this.getOrderInfo, 1000);
          } else {
            this.isOrderLoaded = true;
            this.timeoutId = null;
            console.log('redirect to error page')
            this.$router.push({
              path: "/error"
            });
          }
        });
    },
    // getSSOAppId() {
    //   if (!this.shopId) return;
    //   fetchStoreSsoAppId(this.shopId)
    //     .then((res) => {
    //       console.log("fetchStoreSsoAppId done:", res);
    //       const appId =
    //         res.data.sso && res.data.sso.appId ? res.data.sso.appId : null;
    //       this.$store.commit("updateSSOAppId", appId);
    //     })
    //     .catch((e) => {
    //       console.error("fetchStoreSsoAppId failed:", e);
    //       this.$store.commit("updateSSOAppId", null);
    //     });
    // },
    repay() {
      // 前端不判斷過期與否，統一由後端
      this.$router.push({ name: "Order", params: { token: this.token } });
      // const isTokenExpired =
      //   moment().diff(moment.unix(this.order.expirationTs)) > 0;
      // if (!isTokenExpired) {
      //   this.$router.push({ name: "Order", params: { token: this.token } });
      // } else {
      //   console.log("token expired");
      // }
    },
    gotoPage() {
      const url =
        this.order.context && this.order.context.resultUrl
          ? this.order.context.resultUrl
          : null;
      console.log("gotoPage:" + url);
      if (url) {
        window.location.href = url;
      }
    },
    gotoContinueUrl() {
      if (this.continueUrl) {
        window.location.href = this.continueUrl;
      }
    },
    // getShopSupportedPoints() {
    //   fetchSupportedPoints(this.shopId)
    //     .then((response) => {
    //       console.log(
    //         "done fetchSupportedPoints (store): " +
    //           JSON.stringify(response.data)
    //       );

    //       if (response.data && response.data.points) {
    //         const pointsUniqueByKey = [
    //           ...new Map(
    //             response.data.points.map((item) => [item['pointCode"'], item])
    //           ).values(),
    //         ];
    //         console.log(
    //           "unique supported points:" + JSON.stringify(pointsUniqueByKey)
    //         );
    //         this.$store.commit("updateSupportedPoints", pointsUniqueByKey);
    //       } else {
    //         this.$store.commit("updateSupportedPoints", []);
    //       }
    //     })
    //     .catch((e) => {
    //       console.error("fetchSupportedPoints failed (store)", e.response);
    //       this.$store.commit("updateSupportedPoints", []);
    //     });
    // },
    async getShopSettings() {
      if (!this.shopId) return;
      await this.$store.dispatch("portal/init");
      // fetchStoreServices(this.shopId)
      //   .then((res) => {
      //     console.log(
      //       "[Result] fetchStoreServices done:" + JSON.stringify(res)
      //     );
      //     this.$store.commit("updateShopSettings", res.data);
      //     const appId =
      //       res.data.sso && res.data.sso.appId ? res.data.sso.appId : null;
      //     this.$store.commit("updateSSOAppId", appId);
      //     if (res.data && res.data.points) {
      //       const pointsUniqueByKey = [
      //         ...new Map(
      //           res.data.points.map((item) => [item["pointCode"], item])
      //         ).values(),
      //       ];
      //       this.$store.commit("updateSupportedPoints", pointsUniqueByKey);
      //     } else {
      //       this.$store.commit("updateSupportedPoints", []);
      //     }
      //   })
      //   .catch((e) => {
      //     console.error("fetchStoreServices failed:", e);
      //     this.$store.commit("updateShopSettings", null);
      //     this.$store.commit("updateSSOAppId", null);
      //     this.$store.commit("updateSupportedPoints", []);
      //   });
    },
    getDDCReward() {
      let queryString = null;
      // console.log('====== getDDCReward =====')
      // 沒登入的先不呼叫試算，避免merchant ID為null
      if (!this.isLoggedIn) return;
      
      if (this.userId) {
        queryString = {
          uuid: this.userId,
        };
      }
      fetchDDCRewards(this.merchantId, this.orderId, queryString)
        .then((res) => {
          console.log("fetchDDCRewards done:" + JSON.stringify(res));
          this.rewards = res.data;
        })
        .catch((e) => {
          console.error("fetchDDCRewards failed:", e);
          this.rewards = null;
        });
    },
    // FOR DEMO ONLY
    onClickMore($event, couponIndex) {
      const cards = document.getElementsByClassName("coupon--ddp");
      const target = `couponCard-${couponIndex}`;
      const targetArrow = `couponCardArrow-${couponIndex}`;
      let cardElem = this.$refs[target][0];
      let arrowElem = this.$refs[targetArrow][0];
      var isOpened = cardElem.style.animationName === "cardMore";
      for (var i = 0; i < cards.length; i++) {
        var card = cards.item(i);
        card.style.animationDelay = "0s";
        if (card.style.animationName == "cardMore")
          card.style.animationName = "cardLess";
        card.style.height = "172px";
        card.style.minHeight = "172px";
      }
      if (isOpened) {
        arrowElem.style.animationName = "arrowDown";
        cardElem.style.animationName = "cardLess";
        cardElem.style.height = "172px";
        cardElem.style.minHeight = "172px";
        setTimeout(function () {
          arrowElem.style.transform = "rotate(0deg)";
        }, 500);
      } else {
        arrowElem.style.animationName = "arrowUp";
        cardElem.style.animationName = "cardMore";
        cardElem.style.height = "300px";
        cardElem.style.minHeight = "300px";
        setTimeout(function () {
          arrowElem.style.transform = "rotate(180deg)";
        }, 500);
        setTimeout(function () {
          cardElem.scrollIntoView({ behavior: "smooth" });
        }, 300);
      }
    },
    // getUserPoints() {
    //   if (!this.isLoggedIn) return;
    //   fetchUserPoints(this.merchantId, this.userId, { shop_id: this.shopId })
    //     .then((response) => {
    //       console.log('done fetchUserPoints: ' + JSON.stringify(response));
    //       if (response.data) {
    //         this.$store.commit('updateUserPoints', response.data);
    //       } else {
    //         this.$store.commit('updateUserPoints', []);
    //       }
    //     })
    //     .catch((e) => {
    //       console.error('fetchUserPoints failed', e);
    //       this.$store.commit('updateUserPoints', []);
    //     })
    //     .finally(() => {
    //       this.progressing = false;
    //     });
    // },
  },
};
</script>
